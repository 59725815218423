<template>
  <div class="price-item">
    <div class="price-item__title">{{ price.title }}</div>
    <div class="price-item__spacer"></div>
    <div class="price-item__cost">{{ price.cost }}</div>
  </div>
    <!-- <div class="sb-col_lg-6 sb-col_md-12 sb-col_sm-12 sb-col_xs-12 sb-m-17-top">
      <div class="s-text-list-type-3__item sb-m-clear-top">
        <h3 class="s-text-list-type-3__item-title sb-font-title sb-font-p3">
          <font style="color: #21be6d;">{{ price.title }}</font>
        </h3>
        <div class="s-text-list-type-3__item-line-wrapper s-text-list-type-3__item-line-wrapper_padding-left s-text-list-type-3__item-line-wrapper_padding-right">
          <div class="s-text-list-type-3__item-line"></div>
        </div>
        <div class="s-text-list-type-3__item-description sb-font-p3">
          {{ price.cost }}
        </div>
      </div>
    </div> -->
  </template>
  
  <script>
  export default {
    name: 'PriceItemComponent',
    props: {
      price: {
        type: Object,
        required: true
      }
    }
  }
  </script>
  
<style scoped lang="scss">

.price-item {
  text-align: left;
  display: flex;
  justify-content: space-between;
  width: 100%;
  gap: 0.5rem;
  font-size: 1rem;

  @include media-min(m){
    width: calc(100% / 2 - 1rem);
  }
  
  &__title {
    color: var(--color-brand);
    font-family: var(--font-family-heading);
  }

  &__spacer {
    flex-grow: 1;
    border-bottom: 1px solid var(--color-border);
  }

  &__cost {
    color: var(--color-on-secondary-variant);
  }
}

</style>
  