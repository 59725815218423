<template>
  <div class="input">
    <div class="input__field" :class="{'error': error}">
      <input
        :id="id"
        :name="name"
        :required="required"
        :type="type"
        :pattern="pattern"
        placeholder=""
        @input="onInput"
      />
      <label :for="id">
        {{ label }}
        <span v-if="required">*</span>
      </label>
    </div>
    <div class="input__hint input__error" v-if="error">{{ error }}</div>
  </div>
  
</template>

<script>
  export default {
    props: {
      id: String,
      name: String,
      required: Boolean,
      type: String,
      pattern: String,
      modelValue: {
        type: String,
        default: ''
      },
      label: String,
      class: String,
      error: String
    },
    methods: {
      onInput(event) {
        // console.log(event.target.value);
        this.$emit('update:modelValue', event.target.value);
      }
    },
    watch: {
      modelValue(value) {
        this.$el.querySelector('input').value = value;
      }
    }
  };
</script>
  
<style scoped lang="scss">



</style>
  