<template>
  <Navbar />
  <Hero />
  <Services />
  <Prices />
  <About />
  <Clients />
  <Principles />
  <ContactForm />
  <ContactDetails />
</template>

<script>
import Navbar from '@/components/Navbar.vue'
import Hero from '@/sections/Hero.vue'
import Services from '@/sections/Services.vue'
import Prices from '@/sections/Prices.vue'
import About from '@/sections/About.vue'
import Clients from '@/sections/Clients.vue'
import Principles from '@/sections/Principles.vue'
import ContactForm from '@/sections/ContactForm.vue'
import ContactDetails from '@/sections/ContactDetails.vue'

export default {
  name: 'App',
  components: {
    Navbar,
    Hero,
    Services,
    Prices,
    About,
    Clients,
    Principles,
    ContactForm,
    ContactDetails
  },
}
</script>

<style lang="scss">



</style>
