<template>
  <div class="client-item">
    <div class="client-item__image">
      <img :src="clientLogo" :alt="client.name">
    </div>
  </div>
</template>
  
  <script>
  export default {
    props: {
      client: {
        type: Object,
        required: true
      }
    },
    computed: {
      clientLogo: function() {
        return require(`@/assets/images/clients/${this.client.image}`);
      }
    }
  }
  </script>
  
<style scoped lang="scss">

.client-item {
  display: flex;
  justify-content: center;
  align-items: center;

  &__image {
    img {
      width: 100%;
      height: auto;
    }
  }
}

</style>
  