<template>
  <div class="service-item">
    <div class="service-item__image">
      <img :src="serviceImage" :alt="service.title">
    </div>
    <div class="service-item__content">
      <h3 class="service-item__title">{{ service.title }}</h3>
      <p class="service-item__description">{{ service.description }}</p>
    </div>
  </div>
</template>
  
<script>
  export default {
    props: {
      service: {
        type: Object,
        required: true
      }
    },
    computed: {
      serviceImage() {
        return require(`@/assets/images/services/${this.service.image}`);
      }
    }
  }
</script>
  
<style scoped lang="scss">

.service-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;

  @include media-min(m){
    width: calc(100% / 2 - 2rem);
  }

  @include media-min(l){
    width: calc(100% / 3 - 2rem);
  }

  &__image {
    width: clamp(10rem, 50vw, 20rem);
    height: auto;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__title {
    font-size: var(--font-size-h4);
    font-weight: 300;
    line-height: 1.2;
    margin-bottom: 1rem;
    color: var(--color-on-secondary);
  }

  &__description {
    color: var(--color-on-secondary-variant);
    font-size: var(--font-size-text);
  }
}

</style>
  