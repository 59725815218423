<template>
  <div class="principle-item">
    <div class="principle-item__number">{{ numberFormatted }}</div>
    <div class="principle-item__title">{{ principle }}</div>
  </div>
</template>
  
  <script>
  export default {
    props: {
      principle: {
        type: String,
        required: true
      },
      number: {
        type: Number,
        required: true
      }
    },
    computed: {
      numberFormatted() {
        return this.number.toString().padStart(2, '0') + '.';
      }
    }
  }
  </script>
  
<style scoped lang="scss">

.principle-item {
  display: flex;
  gap: var(--padding-large);
  align-items: center;
  
  &:not(:last-child) {
    padding-bottom: var(--padding-large);
    margin-bottom: var(--padding-large);
    border-bottom: 1px solid var(--color-border);
  }

  &__number {
    color: var(--color-brand);
    font-size: clamp(5rem, 10vw, 8rem);
    //font-family: var(--font-family-heading);
    font-weight: 300;
    flex-shrink: 0;
  }

  &__title {
    font-size: clamp(var(--font-size-text-large), 10vw, var(--font-size-h4));
    font-weight: 300;
    line-height: 1.5;
    color: var(--color-supplement-light-opacity);
  }
}

</style>
  