<template>
  <div class="detail-item">
    <h4 class="detail-item__title">{{ title }}</h4>
    <p class="detail-item__description">{{ details }}</p>
  </div>
</template>
  
  <script>
  export default {
    props: {
      title: String,
      details: String,
    },
  };
  </script>
  
<style scoped lang="scss">

.detail-item {
  &__title {
    font-size: var(--font-size-h5);
    color: var(--color-white-opacity);
  }

  &__description {
    font-size: 1.2rem;
  }
}

</style>
  